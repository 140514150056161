<template>
<b-row class="btb_license_input_label">
        <!-- general information section -->
        <b-col sm="12">
            <div class="group-form-card">
                <b-card>
                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.general_information')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                        <b-row>
                            <!-- when renow time now yet -->
                            <div v-if="!isRenewTime">
                                <b-alert variant="danger" show dismissible>
                                    {{$t('teaGardenPanel.renew_time_not_now')}}
                                </b-alert>
                            </div>
                            <!-- when data not found -->
                            <div v-if="renewDataNotFound">
                                <b-alert variant="danger" show dismissible>
                                    {{$t('teaGardenPanel.no_date_found')}}
                                </b-alert>
                            </div>
                            <!-- license_expired -->
                            <div v-if="license_expired">
                                <b-alert variant="danger" show dismissible>
                                    {{$t('teaGardenPanel.license_expired')}}
                                </b-alert>
                            </div>
                            <!-- application type -->
                            <b-col sm="6">
                                <ValidationProvider name="Application Type" vid="application_type" :rules="{required: true, min_value: 1}">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="application_type">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.application_type') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            :disabled="isRenew"
                                            plain
                                            v-model="application.application_type"
                                            id="application_type"
                                            :options="applicationType"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- search by license no when create renew -->
                            <b-col sm="6" v-if="application.application_type === 2 && licenseSearch">
                                <div class="row">
                                    <b-col sm="7">
                                        <ValidationProvider name="License No" vid="registration_no" :rules="{required: true}">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="registration_no">
                                        <template v-slot:label>
                                            {{ $t('externalUser.license_no') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="text"
                                            v-model="search.registration_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            @keypress="isNumber"
                                            :placeholder="$t('teaGardenPanel.english_number_only')"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="5">
                                        <b-button type="button" @click="existingLicense()" variant="success" class="mt-3 btn-sm">{{ $t('globalTrans.search') }} <b-spinner v-if="isLoading" variant="danger" small label="Spinning"></b-spinner></b-button>
                                    </b-col>
                                </div>
                            </b-col>
                            <!-- old licnese upload -->
                            <b-col sm="6" v-if="application.application_type === 2">
                                <ValidationProvider name="Old License Attachment" vid="old_license_attachment" rules="required">
                                    <b-form-group slot-scope="{ valid, errors }" label-for="old_license_attachment">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.old_license_attachment') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                        <b-form-file
                                            accept=".pdf"
                                            id="old_license_attachment"
                                            v-model="application.old_license_attachment"
                                            @change="onChange($event, 'old_license_attachment')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                        >
                                        </b-form-file>
                                        <a target="_blank" style = "margin-left: 8px;" v-if="application.old_license_attachment && checkBase64(application.old_license_attachment) === false" :href="teaGardenServiceBaseUrl  + application.old_license_attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                        </div>
                                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- old license expaired date -->
                            <b-col sm="6" v-if="application.application_type === 2">
                                <ValidationProvider name="Old License Expaire Date" vid="old_license_expire_date" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-for="old_license_expire_date">
                                    <template v-slot:label>
                                    {{ $t('teaGardenPanel.old_license_expire_date') }} <span class="text-danger">*</span>
                                    </template>
                                    <date-picker
                                    :disabled="is_renew_data_found ? true : false"
                                    id="old_license_expire_date"
                                    v-model="application.old_license_expire_date"
                                    class="form-control"
                                    :placeholder="$t('globalTrans.select')"
                                    :locale="currentLocale"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </date-picker>
                                    <div class="invalid-feedback d-block" v-if="errors.length">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- blank div -->
                            <b-col sm="6" v-if="application.application_type === 1">
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- applicant_name_en  -->
                            <b-col sm="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 33 || application.service_id === 34 || userType === 1">
                            <ValidationProvider name="Applicant Name (En)" vid="applicant_name_en" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="applicant_name_en">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.owner_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input v-if="application.service_id === 30"
                                        :placeholder="$t('teaGardenPanel.broker_name_placeholder_condition')"
                                        type="text"
                                        v-model="application.applicant_name_en"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <b-form-input v-else
                                        type="text"
                                        v-model="application.applicant_name_en"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- applicant_name_bn -->
                            <b-col sm="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 33 || application.service_id === 34 || userType === 1">
                            <ValidationProvider name="Applicant Name (Bn)" vid="applicant_name_bn" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="applicant_name_bn">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.owner_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input v-if="application.service_id === 30"
                                        :placeholder="$t('teaGardenPanel.broker_name_placeholder_condition')"
                                        :disabled="isRenew"
                                        type="text"
                                        v-model="application.applicant_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <b-form-input v-else
                                        :disabled="isRenew"
                                        type="text"
                                        v-model="application.applicant_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- company_name_en  -->
                            <b-col sm="6">
                                <ValidationProvider name="Company Name (En)" vid="company_name_en" rules="required">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="company_name_en">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.applicant_company') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="text"
                                            v-model="application.company_name_en"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- company_name_bn -->
                            <b-col sm="6">
                                <ValidationProvider name="Company Name (Bn)" vid="company_name_bn" rules="required">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="company_name_bn">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.applicant_company') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            :disabled="isRenew"
                                            type="text"
                                            v-model="application.company_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- brand_name_en  -->
                            <b-col sm="6" v-if="application.service_id === 31">
                            <ValidationProvider name="Brand Name (En)" vid="brand_name_en" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="brand_name_en">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.brand_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="text"
                                        v-model="application.brand_name_en"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- brand_name_bn -->
                            <b-col sm="6" v-if="application.service_id === 31">
                            <ValidationProvider name="Brand Name (Bn)" vid="brand_name_bn" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="brand_name_bn">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.brand_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        :disabled="isRenew"
                                        type="text"
                                        v-model="application.brand_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- mobile, nid, email -->
                        </b-row>
                        <b-row>
                            <!-- mobile -->
                            <b-col sm="6">
                            <ValidationProvider name="Mobile" vid="mobile" :rules="{required: true, min: 11, max: 11}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="mobile">
                                    <template v-slot:label>
                                        {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        @keypress="isNumber"
                                        v-model="application.mobile"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- email -->
                            <b-col sm="6">
                                <ValidationProvider name="Email" vid="email" :rules="{required: true, email: true}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="email">
                                    <template v-slot:label>
                                        {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.email"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- nid -->
                            <b-col sm="6" v-if="application.service_id === 31 || application.service_id === 32 || application.service_id === 33 || application.service_id === 30">
                                <ValidationProvider name="NID" vid="nid" :rules="{required: true, min: 10, max: 17}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="nid">
                                    <template v-slot:label>
                                        {{ $t('globalTrans.nid') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.nid"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!----------------------------------- wearhosue input--------------------- -->
                            <!-- floor area  -->
                            <b-col sm="6" v-if="application.service_id === 34">
                                <ValidationProvider name="Floor Area" vid="floor_area" rules="required">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="company_name_en">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.floor_area') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            :disabled="isRenew"
                                            type="text"
                                            @keypress="isNumber"
                                            v-model="application.floor_area"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- land_owned_contract -->
                            <!-- for wearhosue licnese -->
                            <b-col sm="6" v-if="application.service_id === 34">
                                <ValidationProvider name="The tea warehouse is either owned or contract" vid="land_owned_contract" rules="required">
                                        <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-cols-sm="12"
                                            label-for="land_owned_contract"
                                            label-cols-lg="12"
                                            :label="$t('teaGardenPanel.land_owned_contract')" label-size="lg"
                                            label-class="font-weight-bold pt-0" class="mb-0">
                                            <b-form-radio-group
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                class="custom-control-inline-wrapper" size="lg" v-model="application.land_owned_contract" :options="wearhouseLandCheck" name="">
                                            </b-form-radio-group>
                                            <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-card>
            </div>
        </b-col>
        <!-- applicant address section -->
        <b-col sm="12" v-if="application.service_id === 31 || application.service_id === 32 || application.service_id === 33">
            <div class="group-form-card">
                <b-card>
                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.applicant_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                        <b-row>
                            <!-- area section start here -->
                            <!-- area type -->
                            <b-col sm="6">
                                <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="area_type_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.area_type') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.area_type_id"
                                            id="area_type_id"
                                            :options="getAreaTypeList"
                                            @change="GetAreaTypeData(application.area_type_id)"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- division -->
                            <b-col sm="6" v-show="ItemShow">
                                <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="division_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.division') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.division_id"
                                            id="division_id"
                                            :options="divisionList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- district -->
                            <b-col sm="6" v-show="ItemShow">
                                <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="district_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.district') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.district_id"
                                            id="district_id"
                                            :options="districtList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- upazila -->
                            <b-col sm="6" v-show="PauroshobaItemShow || UnionItemShow">
                                <ValidationProvider name="Upazila" vid="upazila_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="upazila_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.upazila') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.upazila_id"
                                            id="upazila_id"
                                            :options="upazilaList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- union -->
                            <b-col sm="6" v-show="UnionItemShow">
                                <ValidationProvider name="Union" vid="union_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="union_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.union') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.union_id"
                                            id="union_id"
                                            :options="unionList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- city_corporation_id -->
                            <b-col sm="6" v-show="CityCorpItemShow">
                                <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="city_corporation_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.city_corporation') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.city_corporation_id"
                                            id="city_corporation_id"
                                            :options="cityCorporationList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- pauroshoba_id -->
                            <b-col sm="6" v-show="PauroshobaItemShow">
                                <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="pauroshoba_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.municipality_name') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.pauroshoba_id"
                                            id="pauroshoba_id"
                                            :options="municipalityList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                            <ValidationProvider name="Address (En)" vid="address_en" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="address_en">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="text"
                                        v-model="application.address_en"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                            <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="address_bn">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="text"
                                        v-model="application.address_bn"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- post code -->
                            <b-col sm="6">
                            <ValidationProvider name="Post Code" vid="post_code" :rules="{required: false}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="post_code">
                                    <template v-slot:label>
                                        {{ $t('globalTrans.post_code') }}
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.post_code"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- area section end -->
                        </b-row>
                    </b-form-group>
                </b-card>
            </div>
        </b-col>
        <!-- company address section -->
        <b-col sm="12">
            <div class="group-form-card">
                <b-card>
                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.business_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                        <b-row>
                        <b-col sm="12" v-if="application.service_id === 31 || application.service_id === 32 || application.service_id === 33">
                            <ValidationProvider name="Address Status" vid="is_same" v-slot="{ errors }">
                                <b-form-group
                                    class="row mb-0"
                                    label-for="is_same"
                                >
                                <b-form-checkbox
                                    v-model="isSameAsApplicant"
                                    value="1"
                                    @change="isSameAsApplicantFunc($event)"
                                    unchecked-value="0"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <span class="text-black">{{ $t('teaGardenPanel.same_as_applicant_address') }}</span>
                                </b-form-checkbox>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                            <!-- area section start here -->
                            <!-- area type -->
                            <b-col sm="6">
                                <ValidationProvider name="Area Type" vid="c_area_type_id" rules="required|min_value:1">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="c_area_type_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.area_type') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.c_area_type_id"
                                            id="c_area_type_id"
                                            :options="getAreaTypeList"
                                            @change="comGetAreaTypeData(application.c_area_type_id)"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- division -->
                            <b-col sm="6" v-show="comItemShow">
                                <ValidationProvider name="Division" vid="c_division_id" rules="required|min_value:1">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="c_division_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.division') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.c_division_id"
                                            id="c_division_id"
                                            :options="divisionList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- district -->
                            <b-col sm="6" v-show="comItemShow">
                                <ValidationProvider name="District" vid="c_district_id" rules="required|min_value:1">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="c_district_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.district') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.c_district_id"
                                            id="c_district_id"
                                            :options="comDistrictList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- upazila -->
                            <b-col sm="6" v-show="comPauroshobaItemShow || comUnionItemShow">
                                <ValidationProvider name="Upazila" vid="c_upazila_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="c_upazila_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.upazila') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.c_upazila_id"
                                            id="c_upazila_id"
                                            :options="comUpazilaList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- union -->
                            <b-col sm="6" v-show="comUnionItemShow">
                                <ValidationProvider name="Union" vid="c_union_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="c_union_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.union') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.c_union_id"
                                            id="c_union_id"
                                            :options="comUnionList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- city corporation -->
                            <b-col sm="6" v-show="comCityCorpItemShow">
                                <ValidationProvider name="City Corporation" vid="c_city_corporation_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="c_city_corporation_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.city_corporation') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.c_city_corporation_id"
                                            id="c_city_corporation_id"
                                            :options="comCityCorporationList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- pauroshova -->
                            <b-col sm="6" v-show="comPauroshobaItemShow">
                                <ValidationProvider name="Municipality" vid="c_pauroshoba_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="c_pauroshoba_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.municipality_name') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.c_pauroshoba_id"
                                            id="c_pauroshoba_id"
                                            :options="comMunicipalityList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- address  -->
                            <b-col sm="6">
                            <ValidationProvider name="Address (En)" vid="address_en" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="address_en">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="text"
                                        v-model="application.c_address_en"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                            <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="address_bn">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="text"
                                        v-model="application.c_address_bn"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- post code -->
                            <b-col sm="6">
                            <ValidationProvider name="Post Code" vid="post_code" :rules="{required: false}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="post_code">
                                    <template v-slot:label>
                                        {{ $t('globalTrans.post_code') }}
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.c_post_code"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- area section end -->
                        </b-row>
                    </b-form-group>
                </b-card>
            </div>
        </b-col>

        <!-- warehouse address section -->
        <b-col sm="12" v-if="application.service_id === 31 || application.service_id === 32 || application.service_id === 33 || application.service_id === 34">
            <div class="group-form-card">
                <b-card>
                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.warehouse_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                        <b-row>
                            <b-col sm="12" v-if="application.service_id === 34">
                                <ValidationProvider name="Address Status" vid="is_same" v-slot="{ errors }">
                                    <b-form-group
                                        class="row mb-0"
                                        label-for="is_same"
                                    >
                                    <b-form-checkbox
                                        v-model="isSameAsBusiness"
                                        value="1"
                                        @change="isSameAsBusinessFunc($event)"
                                        unchecked-value="0"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <span class="text-black">{{ $t('teaGardenPanel.same_as_business_address') }}</span>
                                    </b-form-checkbox>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- area section start here -->
                            <!-- area type -->
                            <b-col sm="6">
                                <ValidationProvider name="Area Type" vid="w_area_type_id" rules="required|min_value:1">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="w_area_type_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.area_type') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.w_area_type_id"
                                            id="w_area_type_id"
                                            :options="getAreaTypeList"
                                            @change="warGetAreaTypeData(application.w_area_type_id)"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- divison -->
                            <b-col sm="6" v-show="warItemShow">
                                <ValidationProvider name="Division" vid="w_division_id" rules="required|min_value:1">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="w_division_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.division') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.w_division_id"
                                            id="w_division_id"
                                            :options="divisionList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- district -->
                            <b-col sm="6" v-show="warItemShow">
                                <ValidationProvider name="District" vid="w_district_id" rules="required|min_value:1">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="w_district_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.district') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.w_district_id"
                                            id="w_district_id"
                                            :options="warDistrictList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- upazila -->
                            <b-col sm="6" v-show="warPauroshobaItemShow || warUnionItemShow">
                                <ValidationProvider name="Upazila" vid="w_upazila_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="w_upazila_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.upazila') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.w_upazila_id"
                                            id="w_upazila_id"
                                            :options="warUpazilaList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- union -->
                            <b-col sm="6" v-show="warUnionItemShow">
                                <ValidationProvider name="Union" vid="w_union_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="w_union_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.union') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.w_union_id"
                                            id="w_union_id"
                                            :options="warUnionList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- city corporation -->
                            <b-col sm="6" v-show="warCityCorpItemShow">
                                <ValidationProvider name="City Corporation" vid="w_city_corporation_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="w_city_corporation_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.city_corporation') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.w_city_corporation_id"
                                            id="w_city_corporation_id"
                                            :options="warCityCorporationList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- pauroshava -->
                            <b-col sm="6" v-show="warPauroshobaItemShow">
                                <ValidationProvider name="Municipality" vid="w_pauroshoba_id">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="w_pauroshoba_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.municipality_name') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.w_pauroshoba_id"
                                            id="w_pauroshoba_id"
                                            :options="warMunicipalityList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- address  -->
                            <b-col sm="6">
                            <ValidationProvider name="Address (En)" vid="w_address_en" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="w_address_en">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="text"
                                        v-model="application.w_address_en"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                            <ValidationProvider name="Address (Bn)" vid="w_address_bn" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="w_address_bn">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="text"
                                        v-model="application.w_address_bn"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- post code -->
                            <b-col sm="6">
                            <ValidationProvider name="Post Code" vid="post_code" :rules="{required: false}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="post_code">
                                    <template v-slot:label>
                                        {{ $t('globalTrans.post_code') }}
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.w_post_code"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- area section end -->
                        </b-row>
                    </b-form-group>
                </b-card>
            </div>
        </b-col>
        <!-- other additional information-->
        <b-col sm="12">
            <div class="group-form-card">
                <b-card>
                    <b-form-group label-cols-lg="3" :label="$t('globalTrans.others')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                        <!-- business type & required files -->
                        <b-row>
                            <!-- business type -->
                            <b-col sm="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 33">
                                <ValidationProvider name="Business Type" vid="business_type_id" :rules="{required: true, min_value: 1}">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="business_type_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.business_type') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="application.business_type_id"
                                            id="business_type_id"
                                            :options="businessTypeList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- business type for wearhouse -->
                            <b-col sm="6" v-if="application.service_id === 34">
                                <ValidationProvider name="Business Type" vid="business_type_id" :rules="{required: true, min_value: 1}">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="business_type_id">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.business_type') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            :disabled="isRenew"
                                            plain
                                            v-model="application.business_type_id"
                                            id="business_type_id"
                                            :options="wearhouseBusinessTypeList"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- attachemnt -->
                            <!-- company_memorandum -->
                            <b-col sm="6" v-if="application.business_type_id === 2">
                                <ValidationProvider name="Company Memorandum" vid="company_memorandum" rules="required">
                                    <b-form-group slot-scope="{ valid, errors }" label-for="company_memorandum">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.company_memorandum') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                        <b-form-file
                                            accept=".pdf"
                                            id="company_memorandum"
                                            v-model="application.company_memorandum"
                                            @change="onChange($event, 'company_memorandum')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                        >
                                        </b-form-file>
                                        <a target="_blank" style = "margin-left: 8px;" v-if="application.company_memorandum && checkBase64(application.company_memorandum) === false" :href="teaGardenServiceBaseUrl  + application.company_memorandum" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                        </div>
                                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- certificate_of_incor -->
                            <b-col sm="6" v-if="application.business_type_id === 2">
                                <ValidationProvider name="Certificate of incorporation" vid="certificate_of_incor" rules="required">
                                    <b-form-group slot-scope="{ valid, errors }" label-for="certificate_of_incor">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.certificate_of_incor') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                        <b-form-file
                                            accept=".pdf"
                                            id="certificate_of_incor"
                                            v-model="application.certificate_of_incor"
                                            @change="onChange($event, 'certificate_of_incor')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                        >
                                        </b-form-file>
                                        <a target="_blank" style="margin-left: 8px;" v-if="application.certificate_of_incor && checkBase64(application.certificate_of_incor) === false" :href="teaGardenServiceBaseUrl  + application.certificate_of_incor" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                        </div>
                                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- agreement_attachment -->
                            <b-col sm="6" v-if="application.business_type_id === 3">
                                <ValidationProvider name="Agreement Attachment" vid="agreement_attachment" rules="required">
                                    <b-form-group slot-scope="{ valid, errors }" label-for="agreement_attachment">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.agreement_attachment') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                        <b-form-file
                                            accept=".pdf"
                                            id="agreement_attachment"
                                            v-model="application.agreement_attachment"
                                            @change="onChange($event, 'agreement_attachment')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                        >
                                        </b-form-file>
                                        <a target="_blank" style="margin-left: 8px;" v-if="application.agreement_attachment && checkBase64(application.agreement_attachment) === false" :href="teaGardenServiceBaseUrl  + application.agreement_attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                        </div>
                                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- farm_reg_certificate -->
                            <b-col sm="6" v-if="application.business_type_id === 3">
                                <ValidationProvider name="Farm Reg Certificate" vid="farm_reg_certificate" rules="required">
                                    <b-form-group slot-scope="{ valid, errors }" label-for="farm_reg_certificate">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.farm_reg_certificate') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                        <b-form-file
                                            accept=".pdf"
                                            id="farm_reg_certificate"
                                            v-model="application.farm_reg_certificate"
                                            @change="onChange($event, 'farm_reg_certificate')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                        >
                                        </b-form-file>
                                        <a target="_blank" style="margin-left: 8px;" v-if="application.farm_reg_certificate && checkBase64(application.farm_reg_certificate) === false" :href="teaGardenServiceBaseUrl  + application.farm_reg_certificate" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                        </div>
                                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- necessary_documents -->
                            <b-col sm="6" v-if="application.business_type_id === 4">
                                <ValidationProvider name="Necessary Documents" vid="necessary_documents" rules="required">
                                    <b-form-group slot-scope="{ valid, errors }" label-for="necessary_documents">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.necessary_documents') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                        <b-form-file
                                            accept=".pdf"
                                            id="necessary_documents"
                                            v-model="application.necessary_documents"
                                            @change="onChange($event, 'necessary_documents')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                        >
                                        </b-form-file>
                                        <a target="_blank" style="margin-left: 8px;" v-if="application.necessary_documents && checkBase64(application.necessary_documents) === false" :href="teaGardenServiceBaseUrl  + application.necessary_documents" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                        </div>
                                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <!-- participant information -->
                        <b-row v-if="application.business_type_id === 3">
                                <!-- participant information -->
                                <b-col sm="6">
                                    <ValidationProvider name="Name and address of partner" vid="participant_info_en">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="participant_info_en">
                                        <template v-slot:label>
                                        {{ $t('teaGardenPanel.participant_info') }} {{ $t('globalTrans.en') }} <span class="text-danger"> * </span>
                                        </template>
                                        <vue-editor
                                        id="participant_info_en"
                                        v-model="application.participant_info_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></vue-editor>
                                        <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                    <b-col sm="6">
                                    <ValidationProvider name="Name and address of partner" vid="participant_info_bn">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="participant_info_bn">
                                        <template v-slot:label>
                                        {{ $t('teaGardenPanel.participant_info') }} {{ $t('globalTrans.bn') }} <span class="text-danger"> * </span>
                                        </template>
                                        <vue-editor
                                        id="participant_info_bn"
                                        v-model="application.participant_info_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></vue-editor>
                                        <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                        </b-row>
                        <!-- for borker license -->
                        <b-row v-if="application.service_id === 30">
                            <!-- establishment_year -->
                            <b-col sm="6">
                            <ValidationProvider name="Establishment Year" vid="establishment_year" :rules="{required: true,min: 4, max: 4}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="establishment_year">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.establishment_year') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.establishment_year"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- authorized_capital -->
                            <b-col sm="6">
                            <ValidationProvider name="Authorized Capital" vid="authorized_capital" :rules="{required: true, min_value: 0}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="authorized_capital">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.authorized_capital') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.authorized_capital"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- no_of_director -->
                            <b-col sm="6">
                            <ValidationProvider name="No of Director" vid="no_of_director" :rules="{required: true, min_value: 0}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="no_of_director">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.no_of_director') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.no_of_director"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- no_of_employee -->
                            <b-col sm="6">
                            <ValidationProvider name="No of Employee" vid="no_of_employee" :rules="{required: true, min_value: 0}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="no_of_employee">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.no_of_employee') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.no_of_employee"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- paid_capital -->
                            <b-col sm="6">
                            <ValidationProvider name="Paid Capital" vid="paid_capital" :rules="{required: true, min_value: 0}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="paid_capital">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.paid_capital') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.paid_capital"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <!-- tin number -->
                            <b-col sm="6">
                            <ValidationProvider name="Tin Number" vid="tin_number" :rules="{required: true, min_value: 1, max: 12}">
                                <b-form-group
                                slot-scope="{ valid, errors }"
                                    label-for="tin_number">
                                    <template v-slot:label>
                                        {{ $t('teaGardenPanel.tin_number') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        @keypress="isNumber"
                                        v-model="application.tin_number"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                        </b-row>
                        <!-- for blender license -->
                        <b-row v-if="application.service_id === 31">
                            <b-col sm="6">
                                <ValidationProvider name="Retail-Wholesale Number" vid="retail_wholesale_number" :rules="{required: true}">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                        label-for="retail_wholesale_number">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.retail_wholesale_number') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="number"
                                            @keypress="isNumber"
                                            v-model="application.retail_wholesale_number"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                </b-col>
                                <b-col sm="6">
                                <ValidationProvider name="Bidder's Number" vid="bidder_number" :rules="{required: true}">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                        label-for="bidder_number">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.bidder_number') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="number"
                                            @keypress="isNumber"
                                            v-model="application.bidder_number"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                </b-col>
                        </b-row>
                        <!-- for blender license -->
                        <b-row v-if="application.service_id === 33">
                            <b-row>
                                <b-col sm="12">
                                    <ValidationProvider name="Have you received any other license from the tea board?" vid="is_license_from_btb" rules="required">
                                        <b-form-group slot-scope="{ valid, errors, ariaDescribedby }" label-for="is_license_from_btb">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.is_license_from_btb') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-radio-group
                                            class="custom-control-inline-wrapper mb-0"
                                            size="lg"
                                            v-model="application.is_license_from_btb"
                                            :options="yesNoList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :aria-describedby="ariaDescribedby"
                                            name="radio-options1"
                                        >
                                        </b-form-radio-group>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-col sm="6" v-if="application.is_license_from_btb == 1">
                                <ValidationProvider name="License Name" vid="license_name" :rules="{required: true}">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                        label-for="license_name">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.license_name') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            v-model="application.license_name"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                </b-col>
                                <b-col sm="6" v-if="application.is_license_from_btb == 1">
                                <ValidationProvider name="License Number" vid="license_number" :rules="{required: true}">
                                    <b-form-group
                                    slot-scope="{ valid, errors }"
                                        label-for="license_number">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.license_number') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="number"
                                            @keypress="isNumber"
                                            v-model="application.license_number"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                </b-col>
                        </b-row>
                    </b-form-group>
                </b-card>
            </div>
        </b-col>
  </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { renewOldLicenseInfoApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import { VueEditor } from 'vue2-editor'
import { mapGetters } from 'vuex'
export default {
    props: ['application', 'isRenew', 'licenseSearch'],
    components: {
        VueEditor
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        CityCorpItemShow: false,
        UnionItemShow: false,
        PauroshobaItemShow: false,
        comCityCorpItemShow: false,
        comPauroshobaItemShow: false,
        comUnionItemShow: false,
        warCityCorpItemShow: false,
        warUnionItemShow: false,
        warPauroshobaItemShow: false,
        userType: this.$store.state.Auth.authUser.user_type,
        ItemShow: false,
        comItemShow: false,
        warItemShow: false,
        isSameAsApplicant: 0,
        isSameAsBusiness: 0,
        districtList: [],
        upazilaList: [],
        unionList: [],
        cityCorporationList: [],
        municipalityList: [],
        comDistrictList: [],
        comUpazilaList: [],
        comUnionList: [],
        comCityCorporationList: [],
        comMunicipalityList: [],
        warDistrictList: [],
        warUpazilaList: [],
        warUnionList: [],
        warCityCorporationList: [],
        warMunicipalityList: [],
        valid: null,
        loading: false,
        editIndex: '',
        search: {
            registration_no: ''
        },
        isLoading: false,
        isRenewTime: true,
        renewDataNotFound: false,
        license_expired: false,
        is_renew_data_found: false
      }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        currentLocale () {
            return this.$i18n.locale
        },
        yesNoList () {
            return [
                { text: this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ', value: 1, text_en: 'Yes', text_bn: 'হ্যাঁ' },
                { text: this.currentLocale === 'en' ? 'No' : 'না', value: 2, text_en: 'No', text_bn: 'না' }
            ]
        },
        businessTypeList () {
          const data = [
            { value: 1, text: this.$i18n.locale === 'bn' ? 'ব্যাক্তি মালিকানাধীন' : 'Individual' },
            { value: 2, text: this.$i18n.locale === 'bn' ? 'লিমিটেড কোম্পানি' : 'Limited Company' },
            { value: 3, text: this.$i18n.locale === 'bn' ? 'অংশীদারি কারবার' : 'Partnership Business' },
            { value: 4, text: this.$i18n.locale === 'bn' ? 'সরকারি / আধা সরকারি / স্বায়ত্তশাসিত প্রতিষ্ঠান' : 'Govt / Semi Govt / Autonomous Institutions' }
          ]
          if (this.application.service_id === 30) {
            return data.filter(item => item.value === 2)
          }
          return data
        },
        wearhouseBusinessTypeList () {
          return [
            { value: 1, text: this.$i18n.locale === 'bn' ? 'ব্যাক্তি মালিকানাধীন' : 'Individual' },
            { value: 2, text: this.$i18n.locale === 'bn' ? 'লিমিটেড কোম্পানি' : 'Limited Company' },
            { value: 3, text: this.$i18n.locale === 'bn' ? 'অংশীদারি কারবার' : 'Partnership Business' }
          ]
        },
        applicationType () {
          return [
            { value: 1, text: this.$i18n.locale === 'bn' ? 'নতুন নিবন্ধন' : 'New Registration' },
            { value: 2, text: this.$i18n.locale === 'bn' ? 'নবায়ন' : 'Renew' }
          ]
        },
        wearhouseLandCheck () {
          return [
            { value: 1, text: this.$i18n.locale === 'bn' ? 'মালিকানাধীন' : 'Owned' },
            { value: 2, text: this.$i18n.locale === 'bn' ? 'চুক্তিবদ্ধ' : 'Contract' }
          ]
        },
        divisionList () {
            return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
        },
        getAreaTypeList: function () {
            return this.$store.state.commonObj.areaTypeList.map(el => {
                return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
            })
        }
    },
    methods: {
       async existingLicense () {
            if (this.search.registration_no) {
                this.search.service_id = this.application.service_id
                let result = ''
                this.isLoading = true

                result = await RestApi.postData(teaGardenServiceBaseUrl, renewOldLicenseInfoApi, this.search)

                if (result.success) {
                    this.comGetAreaTypeData(result.data?.taggable.c_area_type_id)
                    this.GetAreaTypeData(result.data?.taggable.area_type_id)
                    this.warGetAreaTypeData(result.data?.taggable.w_area_type_id)
                    this.$emit('get-renew-data', result.data)
                    this.isRenewTime = true
                    this.renewDataNotFound = false
                    this.is_renew_data_found = true
                    // check license expired years 3 or gether than 3 years, this license consider cenceled
                    if (result.total_years > 2) {
                        this.$emit('license_expired', true)
                        this.license_expired = true
                    } else {
                        this.$emit('license_expired', false)
                        this.license_expired = false
                    }
                } else {
                    this.$emit('license_expired', false)
                    if (result.is_renew_time === false) {
                        this.isRenewTime = false
                        this.renewDataNotFound = false
                    } else {
                        this.isRenewTime = true
                        this.renewDataNotFound = true
                    }
                    this.license_expired = false
                    this.is_renew_data_found = false
                }

                this.isLoading = false
            }
        },
        addressShowHide (application) {
              // company area
              if (application.c_area_type_id === 1) {
                this.comItemShow = true
                this.comCityCorpItemShow = true
                this.comPauroshobaItemShow = false
                this.comUnionItemShow = false
            } else if (application.c_area_type_id === 2) {
                this.comItemShow = true
                this.comCityCorpItemShow = false
                this.comPauroshobaItemShow = true
                this.comUnionItemShow = false
            } else if (application.c_area_type_id === 3) {
                this.comItemShow = true
                this.comCityCorpItemShow = false
                this.comPauroshobaItemShow = false
                this.comUnionItemShow = true
            }
            // warehouse area type
            if (application.w_area_type_id === 1) {
                this.warItemShow = true
                this.warCityCorpItemShow = true
                this.warPauroshobaItemShow = false
                this.warUnionItemShow = false
            } else if (application.w_area_type_id === 2) {
                this.warItemShow = true
                this.warCityCorpItemShow = false
                this.warPauroshobaItemShow = true
                this.warUnionItemShow = false
            } else if (application.w_area_type_id === 3) {
                this.warItemShow = true
                this.warCityCorpItemShow = false
                this.warPauroshobaItemShow = false
                this.warUnionItemShow = true
            }
            // applicant area type
            if (application.area_type_id === 1) {
                this.ItemShow = true
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (application.area_type_id === 2) {
                this.ItemShow = true
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (application.area_type_id === 3) {
                this.ItemShow = true
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
        },
        isSameAsApplicantFunc (ev) {
            this.callAddress(this.application)
            if (this.isSameAsApplicant === '1') {
                // company address
                this.$set(this.application, 'c_area_type_id', this.application.area_type_id)
                this.$set(this.application, 'c_division_id', this.application.division_id)
                this.$set(this.application, 'c_district_id', this.application.district_id)
                this.$set(this.application, 'c_city_corporation_id', this.application.city_corporation_id)
                this.$set(this.application, 'c_pauroshoba_id', this.application.pauroshoba_id)
                this.$set(this.application, 'c_upazila_id', this.application.upazila_id)
                this.$set(this.application, 'c_union_id', this.application.union_id)
                this.$set(this.application, 'c_address_en', this.application.address_en)
                this.$set(this.application, 'c_address_bn', this.application.address_bn)
                this.$set(this.application, 'c_post_code', this.application.post_code)
                // wareshouse address
                this.$set(this.application, 'w_area_type_id', this.application.area_type_id)
                this.$set(this.application, 'w_division_id', this.application.division_id)
                this.$set(this.application, 'w_district_id', this.application.district_id)
                this.$set(this.application, 'w_city_corporation_id', this.application.city_corporation_id)
                this.$set(this.application, 'w_pauroshoba_id', this.application.pauroshoba_id)
                this.$set(this.application, 'w_upazila_id', this.application.upazila_id)
                this.$set(this.application, 'w_union_id', this.application.union_id)
                this.$set(this.application, 'w_address_en', this.application.address_en)
                this.$set(this.application, 'w_address_bn', this.application.address_bn)
                this.$set(this.application, 'w_post_code', this.application.post_code)
                this.addressShowHide(this.application)
            } else {
                // company address
                this.$set(this.application, 'c_area_type_id', 0)
                this.$set(this.application, 'c_division_id', 0)
                this.$set(this.application, 'c_district_id', 0)
                this.$set(this.application, 'c_city_corporation_id', 0)
                this.$set(this.application, 'c_pauroshoba_id', 0)
                this.$set(this.application, 'c_upazila_id', 0)
                this.$set(this.application, 'c_union_id', 0)
                this.$set(this.application, 'c_address_en', '')
                this.$set(this.application, 'c_address_bn', '')
                this.$set(this.application, 'c_post_code', '')
                // wareshouse address
                this.$set(this.application, 'w_area_type_id', 0)
                this.$set(this.application, 'w_division_id', 0)
                this.$set(this.application, 'w_district_id', 0)
                this.$set(this.application, 'w_city_corporation_id', 0)
                this.$set(this.application, 'w_pauroshoba_id', 0)
                this.$set(this.application, 'w_upazila_id', 0)
                this.$set(this.application, 'w_union_id', 0)
                this.$set(this.application, 'w_address_en', '')
                this.$set(this.application, 'w_address_bn', '')
                this.$set(this.application, 'w_post_code', '')
            }
        },
        isSameAsBusinessFunc (ev) {
            this.callAddress(this.application)
            if (this.isSameAsBusiness === '1') {
                // company address
                this.$set(this.application, 'w_area_type_id', this.application.c_area_type_id)
                this.$set(this.application, 'w_division_id', this.application.c_division_id)
                this.$set(this.application, 'w_district_id', this.application.c_district_id)
                this.$set(this.application, 'w_city_corporation_id', this.application.c_city_corporation_id)
                this.$set(this.application, 'w_pauroshoba_id', this.application.c_pauroshoba_id)
                this.$set(this.application, 'w_upazila_id', this.application.c_upazila_id)
                this.$set(this.application, 'w_union_id', this.application.c_union_id)
                this.$set(this.application, 'w_address_en', this.application.c_address_en)
                this.$set(this.application, 'w_address_bn', this.application.c_address_bn)
                this.$set(this.application, 'w_post_code', this.application.c_post_code)
                this.addressShowHide(this.application)
            } else {
                // wareshouse address
                this.$set(this.application, 'w_area_type_id', 0)
                this.$set(this.application, 'w_division_id', 0)
                this.$set(this.application, 'w_district_id', 0)
                this.$set(this.application, 'w_city_corporation_id', 0)
                this.$set(this.application, 'w_pauroshoba_id', 0)
                this.$set(this.application, 'w_upazila_id', 0)
                this.$set(this.application, 'w_union_id', 0)
                this.$set(this.application, 'w_address_en', '')
                this.$set(this.application, 'w_address_bn', '')
                this.$set(this.application, 'w_post_code', '')
            }
        },
        isNumber (evt) {
          helpers.isNumber(evt)
        },
        checkBase64 (string) {
            var result = ''
            result = string.match('data:')
            if (result) {
                return true
            } else {
                return false
            }
        },
        // file upload
        onChange (event, field) {
            const input = event.target
            if (input.files && input.files[0]) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.application[field] = e.target.result
                }
                reader.readAsDataURL(input.files[0])
            } else {
                this.application[field] = ''
            }
        },
        GetAreaTypeData (typeId) {
            this.ItemShow = true
            if (typeId === 1) {
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (typeId === 2) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (typeId === 3) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
        },
        comGetAreaTypeData (typeId) {
            this.comItemShow = true
            if (typeId === 1) {
                this.comCityCorpItemShow = true
                this.comPauroshobaItemShow = false
                this.comUnionItemShow = false
            } else if (typeId === 2) {
                this.comCityCorpItemShow = false
                this.comPauroshobaItemShow = true
                this.comUnionItemShow = false
            } else if (typeId === 3) {
                this.comCityCorpItemShow = false
                this.comPauroshobaItemShow = false
                this.comUnionItemShow = true
            }
        },
        warGetAreaTypeData (typeId) {
            this.warItemShow = true
            if (typeId === 1) {
                this.warCityCorpItemShow = true
                this.warPauroshobaItemShow = false
                this.warUnionItemShow = false
            } else if (typeId === 2) {
                this.warCityCorpItemShow = false
                this.warPauroshobaItemShow = true
                this.warUnionItemShow = false
            } else if (typeId === 3) {
                this.warCityCorpItemShow = false
                this.warPauroshobaItemShow = false
                this.warUnionItemShow = true
            }
        },
        getDistrictList (id) {
            if (id) {
                return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
            } else {
                return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)
            }
        },
        getUpazilaList (id) {
            return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
        },
        getUnionList (upazilaId) {
            return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1 && item.upazila_id === upazilaId)
        },
        getCityCorporationList (districtId) {
            const objectData = this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getPauroshobaList (upazillaId = null) {
        const objectData = this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.upazila_id === upazillaId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        callAddress (application) {
                // company address
                if (application.c_area_type_id) {
                    this.comDistrictList = this.getDistrictList(application.c_division_id)
                    this.comCityCorporationList = this.getCityCorporationList(application.c_district_id)
                    this.comUpazilaList = this.getUpazilaList(application.c_district_id)
                    if (application.c_upazila_id) {
                        this.comMunicipalityList = this.getPauroshobaList(application.c_upazila_id)
                        this.comUnionList = this.getUnionList(application.c_upazila_id)
                    }
                }
                // warhouse addresss
                if (application.w_area_type_id) {
                    this.warDistrictList = this.getDistrictList(application.w_division_id)
                    this.warCityCorporationList = this.getCityCorporationList(application.w_district_id)
                    this.warUpazilaList = this.getUpazilaList(application.w_district_id)
                    if (application.w_upazila_id) {
                        this.warMunicipalityList = this.getPauroshobaList(application.w_upazila_id)
                        this.warUnionList = this.getUnionList(application.w_upazila_id)
                    }
                }
                // applicant address
                if (application.area_type_id) {
                    this.districtList = this.getDistrictList(application.division_id)
                    this.cityCorporationList = this.getCityCorporationList(application.district_id)
                    this.upazilaList = this.getUpazilaList(application.district_id)
                    if (application.upazila_id) {
                        this.municipalityList = this.getPauroshobaList(application.upazila_id)
                        this.unionList = this.getUnionList(application.upazila_id)
                    }
                }
        }
    },
    watch: {
        'search.registration_no': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.application.registration_no = newVal
            }
        },
        'application.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'application.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'application.upazila_id': function (newVal, oldVal) {
            this.municipalityList = this.getPauroshobaList(newVal)
            this.unionList = this.getUnionList(newVal)
        },
        'application.c_division_id': function (newVal, oldVal) {
           this.comDistrictList = this.getDistrictList(newVal)
        },
        'application.c_district_id': function (newVal, oldVal) {
            this.comCityCorporationList = this.getCityCorporationList(newVal)
            this.comUpazilaList = this.getUpazilaList(newVal)
        },
        'application.c_upazila_id': function (newVal, oldVal) {
            this.comMunicipalityList = this.getPauroshobaList(newVal)
            this.comUnionList = this.getUnionList(newVal)
        },
        'application.w_division_id': function (newVal, oldVal) {
            this.warDistrictList = this.getDistrictList(newVal)
        },
        'application.w_district_id': function (newVal, oldVal) {
            this.warCityCorporationList = this.getCityCorporationList(newVal)
            this.warUpazilaList = this.getUpazilaList(newVal)
        },
        'application.w_upazila_id': function (newVal, oldVal) {
            this.warMunicipalityList = this.getPauroshobaList(newVal)
            this.warUnionList = this.getUnionList(newVal)
        },
        'application.service_id': {
            handler: function (newVal, oldVal) {
                if (newVal === 30) {
                    this.application.business_type_id = 2
                }
            },
            immediate: true
        },
        currentLocale: function () {
            // call by division
            if (this.application.division_id) {
                this.districtList = this.getDistrictList(this.application.division_id)
            }
            if (this.application.c_division_id) {
                this.comDistrictList = this.getDistrictList(this.application.c_division_id)
            }
            if (this.application.w_division_id) {
                this.warDistrictList = this.getDistrictList(this.application.w_division_id)
            }
            // call by district
            if (this.application.district_id) {
                this.cityCorporationList = this.getCityCorporationList(this.application.district_id)
                this.upazilaList = this.getUpazilaList(this.application.district_id)
            }
            if (this.application.c_district_id) {
                this.comCityCorporationList = this.getCityCorporationList(this.application.c_district_id)
                this.comUpazilaList = this.getUpazilaList(this.application.c_district_id)
            }
            if (this.application.w_district_id) {
                this.warCityCorporationList = this.getCityCorporationList(this.application.w_district_id)
                this.warUpazilaList = this.getUpazilaList(this.application.w_district_id)
            }
            // call by upazila
            if (this.application.upazila_id) {
                this.municipalityList = this.getPauroshobaList(this.application.upazila_id)
                this.unionList = this.getUnionList(this.application.upazila_id)
            }
            if (this.application.c_upazila_id) {
                this.comMunicipalityList = this.getPauroshobaList(this.application.c_upazila_id)
                this.comUnionList = this.getUnionList(this.application.c_upazila_id)
            }
            if (this.application.w_upazila_id) {
                this.warMunicipalityList = this.getPauroshobaList(this.application.w_upazila_id)
                this.warUnionList = this.getUnionList(this.application.w_upazila_id)
            }
        }
    },
    created () {
        if (this.$route.query.id) {
            // company area type
            this.search.registration_no = this.application.application.registration_no
            this.callAddress(this.application)
            this.addressShowHide(this.application)
        }
    }
}
</script>

<style>
.font-bn .btb_license_input_label .custom-file-label {
  font-size: 14px;
  color: #7c7c7c;
}
.validation_msg {
  font-size: 11px;
}
</style>
